import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PriceInquiryForm.css';

const PriceInquiryForm = ({ card, condition, printing, onClose, userSettings }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const prefilledMessage = `
Name: ${card.name}
Set: ${card.expansionName}
Condition: ${condition}
Printing: ${printing}

I would like to inquire about the price for this card.
  `.trim();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-price-inquiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: userSettings.buylistEmail,
          from: email,
          subject: `Price inquiry for ${card.name}`,
          text: prefilledMessage,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setSubmitSuccess(true);
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmitError(t('Failed to send email. Please try again.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="price-inquiry-form-overlay">
      <div className="price-inquiry-form">
        <h2>{t('Price Inquiry for')} {card.name}</h2>
        {submitSuccess ? (
          <p className="success-message">{t('Your inquiry has been sent successfully!')}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">{t('Your Email')}:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="message">{t('Message')}:</label>
              <textarea
                id="message"
                value={prefilledMessage}
                readOnly
              />
            </div>
            <div className="form-actions">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? t('Sending...') : t('Send Inquiry')}
              </button>
              <button type="button" onClick={onClose}>
                {t('Cancel')}
              </button>
            </div>
            {submitError && <p className="error-message">{submitError}</p>}
          </form>
        )}
      </div>
    </div>
  );
};

export default PriceInquiryForm;
